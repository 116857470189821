import React from 'react'
import { ApiCall, GetApiCall } from "./axios"
import { bucketPathUrl } from './commonApi'
import axios from 'axios'
import stateOfIndia from '../common_components/stateOfIndia.json'
import CommonApiEndPoint from './commonApiEndPoint'
/**
 * IW0077
 * This function is called detect os.
 */
function getDetectOs(navigator) {
   if (navigator === 'MacIntel') {
      return 'OPTION'
   } else {
      return 'ALT'
   }
}
/**
  * IW0118
  * This function is call on if focus on save or save before active element and press tab to focus move on first active field.
  */
function handleFocusTab(e, nextFocusId) {
   if (e.keyCode === 9 && nextFocusId && !e.shiftKey) {
      e?.preventDefault()
      e.stopPropagation()
      window.stop()
      document.getElementById(nextFocusId)?.focus()
   }
}
/**
 * IW0077
 * This function is called when user get cookies
 */
const getCookie = (cname) => {
   cname = (cname !== undefined) ? cname : 'flash_msg'
   const name = `${cname}=`
   const cookieSplitData = document.cookie.split(';')
   for (let i = 0; i < cookieSplitData.length; i++) {
      let cookieData = cookieSplitData[i]
      while (cookieData.charAt(0) === ' ') {
         cookieData = cookieData.substring(1)
      }
      if (cookieData.indexOf(name) === 0) {
         return cookieData.substring(name.length, cookieData.length)
      }
   }
   return null
}

const setCookie = (name, value, excookie) => {
   const systemDate = new Date()
   systemDate.setTime(systemDate.getTime() + (excookie * 60 * 1000))
   const expires = `expires=${systemDate.toUTCString()}`
   document.cookie = `${name}=${value};${expires};path=/; SameSite=None; Secure`
}

function getJsDate(date, format = 'DD-MM-YYYY') {
   const splitDate = date.split('-')
   switch (format) {
      case 'DD-MM-YYYY':
         return new Date(splitDate[2], Number(splitDate[1]) - 1, splitDate[0])
      case 'MM-DD-YYYY':
         return new Date(splitDate[2], Number(splitDate[0]) - 1, splitDate[1])
      case 'YYYY-MM-DD':
         return new Date(splitDate[0], Number(splitDate[1]) - 1, splitDate[2])
      default:
         break
   }
   return new Date()
}
function changeDateSeparator(date = '', existing_separator, replace_separator) {
   return date.replaceAll(existing_separator, replace_separator)
}

function getTime(date) {
   return new Date(date).getTime()
}

function getJsTime(jsDate) {
   return (jsDate).getTime()
}
function handlePageRefresh(event) {
   const e = event || window.event
   // Cancel the event
   e.preventDefault()
   if (e) {
      e.returnValue = '' // Legacy method for cross browser support
   }
   return '' // Legacy method for cross browser support
}
function debounce(fn, time) {
   let timer
   return function (...args) {
      clearTimeout(timer)
      timer = setTimeout(() => {
         fn(...args)
      }, time)
   }
}
const detectBrowser = () => {
   const nAgt = navigator.userAgent
   let browserName = navigator.appName
   let nameOffset, verOffset
   // In Opera, the true version is after "Opera" or after "Version"
   if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
      browserName = "Opera"
   } else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
      // In MSIE, the true version is after "MSIE" in userAgent
      browserName = "Microsoft Internet Explorer"
   } else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
      browserName = "Edge"
   } else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
      // In Chrome, the true version is after "Chrome"
      browserName = "Chrome"
   } else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
      // In Safari, the true version is after "Safari" or after "Version"
      browserName = "Safari"
   } else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
      // In Firefox, the true version is after "Firefox"
      browserName = "Firefox"
   } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      // In most other browsers, "name/version" is at the end of userAgent
      browserName = nAgt.substring(nameOffset, verOffset)
   }
   return browserName
}
/**
 * IW0077
 * This function is called when user get user ip address
 */
const getUserIpAddress = async () => {
   if (localStorage.getItem('user_ip')) {
      return localStorage.getItem('user_ip')
   } else {
      const user_ip = await axios.get('https://api.bigdatacloud.net/data/client-ip').then(response => { return response.data.ipString }).catch(() => '')
      localStorage.setItem('user_ip', user_ip)
      return user_ip
   }
}
/**
 * IW0110
 * This function is called when user get state data
 */
const getStateData = async (country_id) => {
   if (country_id === 101) {
      return stateOfIndia
   } else {
      if (country_id) {
         const header = { 'access-token': localStorage.getItem('access_token') }
         const res = await GetApiCall('GET', `${CommonApiEndPoint.get_states}?id=${country_id}`, header)
         if (res.data.status === 'success' && res.data.statusCode === 200) {
            return res.data.data
         }
      }
   }
}
/**
 * IW0110
 * This function is called when user get city data
 */
const getCityData = async (state_id, company_id = '') => {
   const header = { 'access-token': localStorage.getItem('access_token') }
   const res = await GetApiCall('GET', `${CommonApiEndPoint.get_cities}?id=${state_id}&company_id=${company_id}`, header)
   if (res.data.status === 'success' && res.data.statusCode === 200) {
      return res.data.data
   }
}

const isIosDevice = () => {
   const myParam = detectBrowser()
   if (myParam === 'Safari') {
      return true
   } else {
      return false
   }
}

function generateRandomString(length) {
   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
   let randomString = ''
   for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      randomString += characters.charAt(randomIndex)
   }
   return randomString
}

const channel_logo = {
   1: `${bucketPathUrl}assets/images/channel_logo/Flipkart.png`,
   2: `${bucketPathUrl}assets/images/channel_logo/Amazon.png`,
   3: `${bucketPathUrl}assets/images/channel_logo/Flipkart.png`,
   4: `${bucketPathUrl}assets/images/channel_logo/Amazon.png`,
   5: `${bucketPathUrl}assets/images/channel_logo/meesho.png`,
   6: `${bucketPathUrl}assets/images/channel_logo/Ajio.png`,
   7: `${bucketPathUrl}assets/images/channel_logo/Jio.png`,
   8: `${bucketPathUrl}assets/images/channel_logo/Myntra.png`,
   9: `${bucketPathUrl}assets/images/channel_logo/eBay.png`,
   10: `${bucketPathUrl}assets/images/channel_logo/Shopclues.png`,
   11: `${bucketPathUrl}assets/images/channel_logo/Snapdeal.png`,
   12: `${bucketPathUrl}assets/images/channel_logo/Shopify.png`
}
const channel_name = {
   1: `Flipkart`,
   2: `Amazon`,
   3: `FBF`,
   4: `FBA`,
   5: `meesho`,
   6: `Ajio`,
   7: `Jio`,
   8: `Myntra`,
   9: `eBay`,
   10: `Shopclues`,
   11: `Snapdeal`,
   12: `Shopify`
}
/**
* IW0110
* This function is get connectted channel list
*/
const getConnectedChannel = async (selected_company = [], is_all = true, is_action = 1, channel_type = [], fullfillment_type = '1') => {
   const header = { 'access-token': localStorage.getItem('access_token') }
   let data = {}
   if (is_action === 3) {
      data = {
         channel_type,
         is_action
      }
   } else {
      data = {
         id: !selected_company.length || selected_company[0]?.value === '0' ? '' : selected_company?.map(ele => ele.id),
         is_action,
         fullfillment_type
      }
      if (is_action === 2) {
         delete data['fullfillment_type']
      }
   }
   const res = await ApiCall('POST', `${CommonApiEndPoint.get_activate_channel}`, data, header)
   if (res.data.status === 'success') {
      const item_data = []
      if (is_all) {
         item_data.push({ label: 'All Channel', value: '0' })
      }
      res.data.data.map(item => {
         if (is_action === 3) {
            item_data.push(item)
         } else {
            item_data.push({ label: item.channel_name, value: item.channel_type, logo: channel_logo[item.channel_type] })
         }
      })
      return item_data
   } else {
      return []
   }
}

const handleMouseMove = (event, setBigImagePosition, flag = false) => {
   const smallImageRect = event.target.getBoundingClientRect()
   const smallImagePosition = flag ? smallImageRect.right / window.innerWidth : smallImageRect.top / window.innerHeight
   setBigImagePosition(smallImagePosition)
}

const getFilterData = (otherFilter, flag = false) => {
   const filter_data = []
   const columnField = []
   if (flag) {
      if (otherFilter.columnFilters.length) {
         otherFilter.columnFilters.map((item) => {
            columnField.push({ field: item.id, value: item.value, data_type: item.data_type })
         })
      }
      return JSON.stringify(columnField)
   } else {
      if (otherFilter.is_filter_apply && otherFilter?.filter_value?.length) {
         otherFilter?.filter_value?.forEach(ele => {
            const sub_filter_data = []
            if (ele?.length) {
               ele.forEach(sub_ele => {
                  sub_filter_data.push({ condition: sub_ele.condition, field: sub_ele.field, value: sub_ele.value, data_type: ['status', 'gst_rate', 'action', 'is_return_order', 'payment_type', 'organization_type', 'party_type', 'registration_type'].includes(sub_ele.field) ? 'text' : sub_ele.type })
               })
            }
            filter_data.push(sub_filter_data)
         })
         return JSON.stringify(filter_data)
      }
   }
}

const getAmountColor = (received_fee = 0, original_fee = 0) => {
   if (Number(Math.abs(received_fee)) === Number(Math.abs(original_fee))) {
      return ''
   } else if (Number(Math.abs(received_fee)) > Number(Math.abs(original_fee))) {
      return 'munim-text-red'
   } else {
      return 'munim-text-green'
   }
}

const numberFormat = (value, is_tofixed = false) => {
   const num = Number(value)
   const options = is_tofixed ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : {}
   return num.toLocaleString('en-IN', options)
}

const checkDesktopApp = () => {
   if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') !== -1) {
      return `width=${window.outerWidth},height=${window.outerHeight}`
   } else {
      return ''
   }
}

const getOS = () => {
   const userAgent = window.navigator.userAgent,
      platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod']
   let os = null

   if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS'
   } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS'
   } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows'
   } else if (/Android/.test(userAgent)) {
      os = 'Android'
   } else if (/Linux/.test(platform)) {
      os = 'Linux'
   }

   return os
}
/**
* IW0110
* This function is call on resync channel connection
*/
const channelConnection = (data) => {
   let clientId = ''
   if (['1', '3'].includes(data?.channel_type)) {
      setCookie('connected_channle_data', JSON.stringify(data), 3)
      clientId = ['3'].includes(data?.channel_type) ? process.env.FBF_APP_ID : process.env.FLIPKART_APP_ID
      window.location.href = `${process.env.FLIPKART_AUTHORIZE_URL}client_id=${clientId}&redirect_uri=${process.env.FLIPKART_DIRECT_DOMIN}&response_type=code&scope=Seller_Api&state=${generateRandomString(6)}`
   } else if (['2', '4'].includes(data?.channel_type)) {
      setCookie('connected_channle_data', JSON.stringify(data), 3)
      clientId = ['4'].includes(data?.channel_type) ? process.env.FBA_APP_ID : process.env.AMAZON_APP_ID
      window.location.href = `${process.env.AMAZON_AUTHORIZE_URL}application_id=${clientId}&state=${generateRandomString(6)}&version=beta`
   }
}

const storageMaxSection = (sec_name = '') => {
   const max_storage_sec = {
      floor: 20,
      area: 40,
      bay: 20,
      rack: 1000,
      shelve: 100,
      bin: 50
   }
   return max_storage_sec[sec_name]
}

export { getCookie, getJsDate, getTime, getJsTime, getDetectOs, handlePageRefresh, changeDateSeparator, debounce, detectBrowser, getUserIpAddress, getStateData, getCityData, handleFocusTab, isIosDevice, generateRandomString, setCookie, getConnectedChannel, handleMouseMove, channel_logo, channel_name, getFilterData, getAmountColor, numberFormat, checkDesktopApp, getOS, channelConnection, storageMaxSection }
