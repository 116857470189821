const CommonApiEndPoint = {
  cancel_order: '/cancel-order',
  change_disputed_invoice: '/change-disputed-invoice',
  change_email_mobile: '/change-email-mobile',
  change_password: '/change-password',
  channel_list: "/get-channel-list",
  check_company_name: '/check-company-name',
  company_details: '/company-details',
  company_list: '/company-list',
  connect_channel: '/connect-channel',
  create_area: '/create-area',
  create_bay: '/create-bay',
  create_bin: '/create-bin',
  create_category: '/create-category',
  create_channel_item: '/create-channel-item',
  create_channel_to_master_item: '/create-channel-to-master-item',
  create_commission_template: '/create-commission-template',
  create_company: '/create-company',
  create_floor: '/create-floor',
  create_item_type: '/create-item-type',
  create_location: '/create-location',
  create_master_item: '/create-master-item',
  create_new_category: '/create-new-category',
  create_rack: '/create-rack',
  create_shelve: '/create-shelve',
  create_tag: '/create-tag',
  create_variant_options: '/create-variant-options',
  create_warehouse: '/create-warehouse',
  delete_category: '/delete-category',
  delete_channel_item: '/delete-channel-item',
  delete_commission_template: '/delete-commission-template',
  delete_entry: '/delete-entry',
  delete_location: '/delete-location',
  exists_master_sku: '/exists-master-sku',
  fetch_channel_item: '/fetch-channel-item',
  fix_alert_issues: '/fix-alert-issues',
  forgot_password: '/forgot-password',
  generate_claim_excel: '/generate-claim-excel',
  generate_label: '/generate-label',
  generate_login_otp: '/generate-login-otp',
  generate_manifest: '/generate-manifest',
  generate_otp: '/generate-otp',
  get_activate_channel: '/get-activate-channel',
  get_alert_details_list: '/get-alert-detail-list',
  get_alert_list: '/get-alert-list',
  get_alert_status: '/get-alert-status',
  get_all_cities: '/get-all-cities',
  get_all_order_report: '/get-all-order-report',
  get_all_states: '/get-all-states',
  get_area_details: '/get-area-details',
  get_area_list: '/get-area-list',
  get_bay_details: '/get-bay-details',
  get_bay_list: '/get-bay-list',
  get_bin_details: '/get-bin-details',
  get_bin_list: '/get-bin-list',
  get_category_details: '/get-category-details',
  get_category_list: '/get-category-list',
  get_category_wise_item_list: '/get-category-wise-item-list',
  get_category: '/get-category',
  get_chan_odr_analysis: '/get-chan-odr-analysis',
  get_channel_item_details: '/get-channel-item-details',
  get_channel_item_list: '/get-channel-item-list',
  get_channel_item_rate_list: '/get-channel-item-rate-list',
  get_channel_mapped_items: '/get-channel-mapped-items',
  get_cities: '/get-cities',
  get_claim_list: '/get-claim-list',
  get_commission_template_list: '/get-commission-template-list',
  get_connect_channel_details: "/get-connect-channel-details",
  get_connect_channel: '/get-connect-channel',
  get_floor_details: '/get-floor-details',
  get_floor_list: '/get-floor-list',
  get_general_setting: '/get-general-setting',
  get_gst_info: '/get-gst-info',
  get_gstr1: '/get-gstr1',
  get_import_log_list: '/get-import-log-list',
  get_import_progress: "/get-import-progress",
  get_import_sample_file: '/get-import-sample-file',
  get_item_type: '/get-item-type',
  get_location_details: '/get-location-details',
  get_location_list: '/get-location-list',
  get_mapped_item_count: '/get-mapped-item-count',
  get_master_channel_item: '/get-master-channel-item',
  get_master_import_list: "/get-master-import-list",
  get_master_item_data_list: '/get-master-item-data-list',
  get_master_item_details: '/get-master-item-details',
  get_master_item_list: '/get-master-item-list',
  get_master_mapped_items: '/get-master-mapped-items',
  get_my_company_list: '/get-my-company-list',
  get_notification_count: '/get-notification-count',
  get_notification_list: '/get-notification-list',
  get_odr_move_analysis: '/get-odr-move-analysis',
  get_order_details: '/get-order-details',
  get_order_list: '/get-order-list',
  get_payment_details: '/get-payment-details',
  get_payment_list: '/get-payment-list',
  get_profile: '/get-gst-utility-user-details',
  get_qty_vs_rev_analysis: '/get-qty-vs-rev-analysis',
  get_rack_details: '/get-rack-details',
  get_rack_list: '/get-rack-list',
  get_return_order_details: '/get-return-order-details',
  get_return_order_list: '/get-return-order-list',
  get_rev_analysis: '/get-rev-analysis',
  get_rtn_analysis: '/get-rtn-analysis',
  get_sales_report: '/get-sales-report',
  get_sales_return_report: '/get-sales-return-report',
  get_same_items_variant: '/get-same-items-variant',
  get_sell_rtn_sku_analysis: '/get-sell-rtn-sku-analysis',
  get_sell_rtn_state_analysis: '/get-sell-rtn-state-analysis',
  get_shelve_details: '/get-shelve-details',
  get_shelve_list: '/get-shelve-list',
  get_single_sku_detail: '/get-single-sku-detail',
  get_tag: '/get-tag',
  get_today_odr_analysis: '/get-today-odr-analysis',
  get_up_down_perfomace: '/get-up-down-perfomace',
  get_user_activity: '/get-user-activity',
  get_variant_options: '/get-variant-options',
  get_warehouse_details: '/get-warehouse-details',
  get_warehouse_list: '/get-warehouse-list',
  get_year_list: '/get-year-list',
  import_amazon_invoice_report: '/import-amazon-invoice-report',
  import_channel_item: "/import-channel-item",
  import_master_item: '/import-master-item',
  import_payment_report: '/import-payment-report ',
  import_spf_claim_report: '/import-spf-claim-report',
  incomplete_user_profile_status: '/incomplete-user-profile-status',
  insert_pincode: '/insert-pincode',
  item_rate_details: '/item-rate-details',
  label_reprint: '/label-reprint',
  log_in: '/log-in',
  map_channel_items: "/map-channel-items",
  master_item_image_upload: '/master-item-image-upload',
  order_dispatch: '/order-dispatch',
  order_export: '/order-export',
  order_status_sync: '/order-status-sync',
  order_sync: '/order-sync',
  payment_export: '/payment-export',
  pincode_verify: '/pincode-verify',
  qty_vs_rev_analysis_report: '/qty-vs-rev-analysis-report',
  remove_assign_category: '/remove-assign-category',
  renew_token: '/renew-token',
  repeat_reconciliation: '/repeat-reconciliation',
  request_otc: '/request-otc',
  reset_password: '/reset-password',
  return_order_approve: '/return-order-approve',
  return_order_export: '/return-order-export',
  return_order_image_upload: '/return-order-image-upload',
  return_order_sync: '/return-order-sync',
  sell_rtn_sku_report: '/sell-rtn-sku-report',
  sell_rtn_state_analysis_report: '/sell-rtn-state-analysis-report',
  unmap_channel_items: '/unmap-channel-items',
  upate_commission_template: '/update-commission-template',
  update_category: '/update-category',
  update_channel_item_commission: '/update-channel-item-commission',
  update_channel_item: '/update-channel-item',
  update_claim_status: '/update-claim-status',
  update_commission_rate: '/update-commission-rate',
  update_company: '/update-company',
  update_connect_channel: '/update-connect-channel',
  update_entry: '/update-entry',
  update_general_setting: '/update-general-setting',
  update_import_progress: "/hide-import-progressbar",
  update_last_selected_company: '/update-last-selected-company',
  update_location: '/update-location',
  update_master_item: '/update-master-item',
  update_notification_token: '/update-notification-token',
  update_notification: '/update-notification',
  update_profile: '/update-profile',
  update_warehouse: '/update-warehouse',
  verify_password: '/verify-password',
  verify_user_otp: '/verify-user-otp',
  view_return_order_details: '/view-return-order-details',
  without_verify_gstin: '/without-verify-gstin'
}
export default CommonApiEndPoint
